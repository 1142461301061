import { GetStaticProps } from 'next'
import { useMemo } from 'react'
import dynamic from 'next/dynamic'
import Head from 'components/Head'
import Navbar from 'components/Navbar'
import Footer from 'components/Footer'
import company from 'config/company.json'
import boatValues from 'config/values.json'
import promos from 'config/promos'
import { initHeaders } from 'utils/request/headers'
import { loadTranslations } from 'utils/request/i18n'
import {
  getHomepageTerritoryMap,
  HomeContent,
  HomeDestinations,
  HomeHero,
  listBanners,
  listDestinationAreas,
  listDestinationTerritoryHubs,
  listOurDestinations,
  NavigationProvider,
  useCompanySchema,
  useLocaleState,
  useTranslation
} from '@sailogy/shipwright'
import {
  BannerInfo,
  DestinationListed,
  ParsedParagraph,
  SeoDestinationHomepage
} from '@sailogy/types'
import { i18nPath, K } from 'i18n/home'

const NavbarBanner = dynamic(
  () => import('@sailogy/components/Banner'),
  { ssr: true }
)
const PaidLandingEnquiry = dynamic(
  () => import('@sailogy/containers/Enquiry/Landing'),
  { ssr: false }
)

interface Props {
  banners: BannerInfo[]
  areas: DestinationListed[]
  destinations: DestinationListed[]
  seoDestinations?: SeoDestinationHomepage
}


const HomePage: React.FC<Props> = ({
  areas: ssrAreas,
  destinations: ssrDestinations,
  banners: ssrBanners
}) => {
  const t = useTranslation<K>()
  const structuredData = useCompanySchema(company)
  const enquiryId = 'enquiry'
  const [banners] = useLocaleState<BannerInfo[]>(
    ssrBanners, async (signal: AbortSignal) => (
      listBanners({ position: 'homepage' } as any, { signal })
        .then(({ results }) => results)
    )
  )
  const [areas] = useLocaleState<DestinationListed[]>(
    ssrAreas, async (signal: AbortSignal) => (
      listDestinationAreas({ hot: true }, { signal })
        .then(({ results }) => results)
    )
  )
  const [destinations] = useLocaleState<DestinationListed[]>(
    ssrDestinations, async (signal: AbortSignal) => (
      listOurDestinations(undefined, { signal })
        .then(({ results }) => results)
    )
  )
  const image = useMemo(() => (
    promos.black_friday ? 'https://sailogy-pictures-public.imgix.net/images/bg/bw_bg.jpg'
      : 'https://sailogy-pictures-public.imgix.net/images/bg/homepage.jpeg'
  ), [promos.black_friday])
  const logoType = useMemo(() => (
    promos.black_friday ? 'https://sailogy-pictures-public.imgix.net/images/bg/bw_lt.svg' : undefined
  ), [promos.black_friday])

  const content: ParsedParagraph[] = [{
    title: t('More than a charter experience'),
    content: (
      <>
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: t('At <strong>Five Seasons Yachting</strong> we pride ourselves on operating a <strong>high-quality charter fleet</strong> at carefully selected locations worldwide. What sets us apart is not just the yachts we manage but the <strong>dedicated team behind them</strong>, ensuring excellence at every step.') }}
        />
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: t('Our bases are staffed by <strong>highly skilled professionals</strong>, all rigorously trained to provide unmatched support and expertise. From fleet management to yacht sales and charter services, our team\'s commitment to excellence ensures that your experience is smooth, reliable, and extraordinary.') }}
        />
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: t('With a focus on maintaining a <strong>premium fleet</strong> and delivering top-tier service across our many locations, we create more than a sailing holiday - we create a yachting experience where every detail is meticulously managed, leaving you free to enjoy the journey.') }}
        />
      </>
    ),
    image: 'https://sailogy-pictures-public.imgix.net/img/home1.jpeg',
    image_alignment: 'right'
  }, {
    title: t('From the Med to the Caribbean - with us, you find the perfect yacht for your sailing experience'),
    content: (
      <p// eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: t('In all aspects of your time at sea, we got you covered: from chartering your sailing yacht to helping you become a proud owner. Or if you think of Yachtinvest programs and other tailor-made solutions of yacht ownership, we at Five Seasons Yachting will provide you with a wide spectrum of opportunities. Talk to us - we are always happy to help.') }}
      />
    ),
    image: 'https://sailogy-pictures-public.imgix.net/img/home2.jpeg',
    image_height: 260,
    image_alignment: 'left'
  }]

  return (
    <NavigationProvider path="/">
      <Head
        title={t('Online Yacht Charter for the Best Sailing Holidays')}
        description={t('Charter yachts in selected destinations. Our fleet offers high quality sailing yachts and catamarans. More than 150 boats wait in several destinations - from the Balearic Islands and Croatia to the Carribean. Book now for a first-class sailing experience!', {
          destination_count: boatValues.destination_count,
          boat_count: boatValues.boat_count
        })}
        schema={structuredData}
      />
      {
        (banners && banners.length > 0) && (
          <NavbarBanner
            title={banners[0].title}
            body={banners[0].body}
            base64
            image={banners[0].image}
            imageMobile={banners[0].mobile_image}
            href={banners[0].action_url}
            lightText={banners[0].light_text}
            backgroundColor={banners[0].hex_color}
            deadline={banners[0].countdown_deadline}
            countdownTitle={banners[0].countdown_running_title}
            countdownBody={banners[0].countdown_running_body}
            countdownHref={banners[0].countdown_running_action_url}
            cta={banners[0].cta_button}
          />
        )
      }
      <div style={{ position: 'relative' }}>
        <Navbar overlay />
        <HomeHero
          title="FIVE SEASONS YACHTING"
          subtitle="EXPLORE THE UNEXPECTED"
          excerpt=""
          image={image}
          logoType={logoType}
          useDestinationSelect
        />
      </div>
      {/* <HomepageBanner /> */
      }
      <HomeDestinations
        areas={areas}
        destinations={destinations}
        hideDiscoverSection
        hideBoatTypeSection
        hideDescription
      />
      {/* <HomeEnquiryAnchor enquiryId={enquiryId} /> */}
      <HomeContent paragraphs={content} use5SYPillars hidePillars />
      {/* <HomeBlog magazine="Magister Navis" hideBlogArticles /> */}
      <PaidLandingEnquiry anchor={enquiryId} />
      {/* <SeoDestinationMap seoDestinations={seoDestinations} /> */
      }
      <Footer />
    </NavigationProvider>
  )
}

// Build SSG pages for each language
export const getStaticProps: GetStaticProps<Props> = async (ctx) => {
  const [
    i18n,
    { results: banners },
    { results: areas },
    { results: destinations }
  ] = await Promise.all([
    loadTranslations(ctx.locale!, i18nPath),
    listBanners({ position: 'homepage' } as any, initHeaders(ctx)),
    listDestinationAreas({ hot: true }, initHeaders(ctx)),
    listOurDestinations(undefined, initHeaders(ctx)),
    listDestinationTerritoryHubs(initHeaders(ctx))
  ])
  const seoDestinations = await getHomepageTerritoryMap(initHeaders(ctx))
  return {
    props: {
      ...ctx.params,
      i18n,
      i18nPath,
      banners,
      areas,
      destinations,
      seoDestinations
    },
    revalidate: 21600 // 6h
  }
}

export default HomePage
